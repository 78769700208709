import { categoriesDataProviderPropsMap } from '../CategoriesDataProvider';
import { isMA } from '../../selectors/isMA';
import { handleError } from '../ErrorHandler/errorHandlerPropsMap';
import { getMockedChallenges } from '../main/getMockedChallenges';
import { getUserFromConfig } from '../User/helpers/userContextHelpers';
import { getCategoriesSetup } from '../../selectors/categories';

import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { ISettingsContextValue } from '@wix/tpa-settings';

import {
  ListChallengesResponse,
  MemberRole,
  SortingCriterion,
  State,
} from '@wix/ambassador-challenges-v1-challenge/types';
import { listChallenges } from '@wix/ambassador-challenges-v1-challenge/http';
import memoize from 'lodash/memoize';
import { request } from '../../services/request';
import { WarmupData } from '../../services/WarmupData';
import { leaveProgram } from '../User/helpers/leaveProgram';
import { cancelInvite } from '../User/helpers/cancelInvite';
import {
  generateLinksToProgramForList,
  isUserGoesToParticipantPage,
  navigateToProgramPage,
} from '../Location/helpers/generateLinksToProgramForList';

const WARMUP_DATA_KEY = 'programsList';

export interface IChallengesListDataProps {
  challengesListData: ListChallengesResponse;
  linksToProgram: {
    [id: string]: string;
  };
  goToProgramPage: (challengeId: string) => unknown;
  leaveProgram: (participantId: string, challengeId: string) => Promise<void>;
  cancelJoinRequest: (challengeId: string) => Promise<void>;
}

const LIMIT = 100;

const loadChallenges = async ({
  flowAPI,
  memberId,
  offset = 0,
  options = {},
}: {
  flowAPI: ControllerFlowAPI;
  memberId: string;
  offset: number;
  options?: any;
}): Promise<ListChallengesResponse> => {
  const params: any = {
    memberId,
    paging: {
      offset,
      limit: LIMIT,
    },
    states: [State.PUBLISHED, State.FINISHED],
    memberRoles: options.memberRoles || [],
  };

  if (options.sortingCriterion) {
    params.sortingCriterion = options.sortingCriterion;
  }

  if (options.categoryIds) {
    params.categoryIds = options.categoryIds;
  }

  return (await request(flowAPI, listChallenges(params)))?.data;
};

async function loadAllChallenges({
  flowAPI,
  memberId,
  options = {},
}: {
  flowAPI: ControllerFlowAPI;
  memberId: string;
  options?: any;
}): Promise<ListChallengesResponse> {
  const result = {
    totalCount: 0,
    memberChallenges: [],
  };

  try {
    const initial = await loadChallenges({
      flowAPI,
      memberId,
      offset: 0,
      options,
    });

    result.totalCount = initial.totalCount;
    result.memberChallenges = initial.memberChallenges;

    if (initial?.memberChallenges?.length === LIMIT) {
      const promises: Promise<ListChallengesResponse>[] = [];

      for (
        let requestedCont = LIMIT;
        requestedCont < initial.totalCount;
        requestedCont += LIMIT
      ) {
        promises.push(
          loadChallenges({
            flowAPI,
            memberId,
            offset: requestedCont,
            options,
          }),
        );
      }

      const nextChallenges = await Promise.all(promises);

      result.memberChallenges = [
        ...initial.memberChallenges,
        ...nextChallenges
          .map((item) => item.memberChallenges)
          .reduce((prev, current) => {
            return [...prev, ...current];
          }, []),
      ];
    }
  } catch (error) {
    console.error(error);
    handleError({
      error,
      context: 'getChallengesList',
    });
  }

  return result;
}

async function handleUserLogin(flowAPI: ControllerFlowAPI) {
  flowAPI.controllerConfig.wixCodeApi.user.onLogin(async (user) => {
    flowAPI.controllerConfig.setProps({
      challengesListData: {
        ...(await loadAllChallenges({ flowAPI, memberId: user.id })),
      },
    });
  });
}

export const challengesListDataProviderPropsMap = memoize(async function (
  flowAPI: ControllerFlowAPI,
): Promise<IChallengesListDataProps> {
  const { isViewer } = flowAPI.environment;
  const userData = await getUserFromConfig(flowAPI.controllerConfig);
  const memberId = userData.id;
  const categories = (await categoriesDataProviderPropsMap(flowAPI))
    ?.categoriesData?.categories;

  const warmupData = new WarmupData(flowAPI);

  const {
    isCategoriesEnabled,
    chosenCategoryFromSettings,
    isDefaultCategory,
    isExistingInDBCategory,
  } = getCategoriesSetup(flowAPI.settings as ISettingsContextValue, categories);
  const chosenCategory =
    isCategoriesEnabled && !isDefaultCategory && isExistingInDBCategory
      ? chosenCategoryFromSettings
      : null;

  await handleUserLogin(flowAPI);

  let challenges = await loadAllChallenges({
    flowAPI,
    memberId: userData.loggedIn ? memberId : undefined,
    options: {
      memberRoles: isMA(flowAPI) && isViewer ? [MemberRole.PARTICIPANT] : [],
      sortingCriterion: isMA(flowAPI)
        ? SortingCriterion.ACTIVE_FIRST
        : SortingCriterion.DEFAULT,
      categoryIds: chosenCategory && isViewer ? [chosenCategory] : null,
    },
  });

  if (!isMA(flowAPI) && !isViewer && (!challenges || !challenges.totalCount)) {
    challenges = getMockedChallenges(flowAPI) as any;
  }

  warmupData.set(WARMUP_DATA_KEY, challenges);

  const goToProgramPage = async (programId: string) => {
    const memberProgram = challenges.memberChallenges.find(
      (i) => i.challenge.id === programId,
    );
    if (!memberProgram) {
      return;
    }
    await navigateToProgramPage(flowAPI, {
      challengeId: memberProgram.challenge.id,
      isParticipant: isUserGoesToParticipantPage(memberProgram?.summary),
    });
  };

  return {
    challengesListData: {
      ...challenges,
    },
    leaveProgram: async (participantId: string, challengeId: string) => {
      await leaveProgram(flowAPI, participantId, challengeId);
    },
    linksToProgram: await generateLinksToProgramForList(
      flowAPI,
      challenges?.memberChallenges,
    ),
    cancelJoinRequest: async (challengeId: string) => {
      await cancelInvite(flowAPI, challengeId);
    },
    goToProgramPage,
  };
});
